<template>
<div>
  <Header />
<div class="mt 3 mx-auto col-sm-12	col-md-12	col-lg-8 col-xl-8 banner overflow-hidden">
    <div class="row mt-5  d-flex content-justify-middle">
        <div class="col-1 text-right px-0">
            <img src="@/assets/estrella.svg" class="title-star mt-2 section-icon workshop-title-icon" /> 
        </div>
        <div class="col-11 px-0">
            <span class="red-title red-text"> Find your inner Powers</span>
            
        </div>
        
    </div>
    <div class="row d-flex justify-content-center">   
        <div class="col-1"> </div>
        <div class="col-11 px-0">
                <span class="learning-goal-title">Train like a hero and collect them all</span>
        </div>
    </div>
       
    <div class="row">
        <div class="col-12 d-flex flex-wrap">
          
            <div class="mx-auto mt-3 col-6 col-lg-4 col-md-4 d-inline mt-5" :key="key" v-for="(workshop,key) in workshops">
              
                <div class="text-center"> 
                  <router-link :to="{ name: 'workshop', params: { id: workshop.Id }}">
                    <img  class="learning-goal-image hover-image clickable" :src="getImage(key)" width="70%" /> 
                  </router-link>
                  </div>
                <div class="learning-goal-title align-text-bottom text-center mt-5"> {{workshop.Title}} </div>
                <div class="learning-goal-text text-center mt-4"> {{workshop.Description.substring(0, 106)}}
                   <router-link class="learning-goal-text" :to="{ name: 'workshop', params: { id: workshop.Id }}"><span class="learning-goal-text"> ...learn more</span> </router-link>
                </div>
              
            </div>
          
        </div>
    </div>


      <div class="row mt-3 mb-4">
                <div class="col-4"></div>
                <div class="d-flex justify-content-center  col-4 p-0">
                    <div class="mr-2">
                       <a :href="Contacts.Whatsapp" target="_blank">   
                        <img class="workshop-contact" src="@/assets/whatsapp.svg" /> 
                      </a>
                    </div>
                    <div class="">
                      <a :href="Contacts.Mobile" target="_blank"> 
                        <img class="workshop-contact" src="@/assets/telefono.svg" /> 
                      </a>
                    </div>
                </div>
                <div class="col-4"></div>
    </div>
</div>
   


  <Footer/>
</div>
</template>

<script>
// S E R V I C E S

import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import WorkshopService from '@/service/workshop.js'
import ContactService from "@/service/contact"

  export default {
     components: {
       Header,
       Footer
  },
    data() {
      return {
        workshops:[],
        isLoged:false,
        Contacts:{
            Whatsapp: '',
            LinkiedIn: '',
            Youtube: '',
            Mobile: ''
        },
      }
    },

      mounted(){
      window.scrollTo(0,0);
      this.checkLogin();
      this.getWorkshops();
      this.getContacts();
    },

    methods:{
       getWorkshops(){

       WorkshopService.getWorkshops().then(res => { 
            this.workshops = res.data;
            console.log(this.workshops)
        });

    },
    getContacts(){
        ContactService.getContacts().then((res)=>{
            this.Contacts.Whatsapp =res.data.Whatsapp;
            this.Contacts.LinkiedIn =res.data.LinkiedIn;
            this.Contacts.Youtube =res.data.Youtube;
            this.Contacts.Mobile =res.data.Mobile;
        })
    },
      checkLogin(){
        if (sessionStorage.getItem("token")!= undefined && sessionStorage.getItem("token")!= "") {
          this.isLoged = true;
        }
      },

      getImage(key){
           return this.workshops[key].Image;

      }
      
    }
  }
</script>

